import React, { useCallback, useEffect, useState } from "react"
import axios from "axios"
import Layout from "../components/layout/layout"
import "./mystyles.scss"
import "react-image-gallery/styles/css/image-gallery.css"
import { Helmet } from "react-helmet"

import Carousel, { Modal, ModalGateway } from "react-images"
import Gallery from "react-photo-gallery"

export default function Photos() {
  const [images, setImages] = useState([])
  const [currentImage, setCurrentImage] = useState(0)
  const [viewerIsOpen, setViewerIsOpen] = useState(false)

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index)
    setViewerIsOpen(true)
  }, [])

  const closeLightbox = () => {
    setCurrentImage(0)
    setViewerIsOpen(false)
  }

  // useEffect(() => {
  //   let shouldCancel = false

  //   const call = async () => {
  //     const response = await axios.get(
  //       "https://google-photos-album-demo2.glitch.me/Qw5vokPD6wNznt7J8"
  //     )
  //     if (!shouldCancel && response.data && response.data.length > 0) {
  //       if (images?.length !== response.data.length) {
  //         const _images = []
  //         response.data.reverse().forEach(src => {
  //           const _img = new Image()
  //           _img.onload = () => {
  //             const { naturalWidth, naturalHeight } = _img
  //             const toPush = {
  //               src: src,
  //               width: naturalWidth,
  //               height: naturalHeight,
  //             }
  //             _images.push(toPush)
  //           }
  //           _img.src = src
  //         })
  //         return _images
  //       } else {
  //         return null
  //       }
  //     }
  //   }

  //   const show = async () => {
  //     const imgArr = await call()
  //     if (imgArr) {
  //       setImages(imgArr)
  //     }
  //   }

  //   show()

  //   return () => (shouldCancel = true)
  // }, [images?.length])

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Galerie</title>
      </Helmet>
      <section className="hero is-light is-bold">
        <div className="hero-body">
          <div className="container">
            <h1 className="title">Galerie {images?.length}</h1>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column">
              En cours de construction
              {/* {JSON.stringify(images)} */}
              {/* {images.length === 0 ? (
                <div>
                  <Gallery
                    photos={images}
                    onClick={openLightbox}
                    nb={images.length}
                  />
                  <ModalGateway>
                    {viewerIsOpen ? (
                      <Modal onClose={closeLightbox}>
                        <Carousel
                          currentIndex={currentImage}
                          views={images.map(x => ({
                            height: x.height,
                            width: x.width,
                            src: x.src + "=d",
                            srcset: x.srcSet,
                            caption: x.title,
                          }))}
                        />
                      </Modal>
                    ) : null}
                  </ModalGateway>
                </div>
              ) : (
                <progress className="progress is-large is-info" max="100">
                  60%
                </progress>
              )} */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
